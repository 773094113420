import React, { useState } from "react";
import TitleBlock from "../../components/TitleBlock";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";


const Advantages = () => {

  const [loading, SetLoading] = useState(false);

  return (
    loading
      ? <Loader />
      : <section className="page">
        <div className="bg_img"></div>
        <TitleBlock title={'Переваги «ЛЕЗ Бізнес»'}></TitleBlock>
        <Container className="page__contaier">
          <Row>
            <Col>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px", marginTop: "200px" }}>
                Чому Ви оберете саме нашу компанію?
              </div>
            </Col>
          </Row>
          <Row >
            <div className='advantages'>
              <div className='advantages__cards'>
                <div className='advantages__card'>
                  <div className='advantages__card--image'>
                    <img src="./../../img/advantages/tarif.svg" alt="" />
                  </div>
                  <div className='advantages__card--title'>
                    Ефективні тарифи
                  </div>
                  <div className='advantages__card--text'>
                    Ми пропонуємо прозорі та зрозумілі тарифи без  прихованих переплат та додаткових платних послуг.
                  </div>
                </div>
                <div className='advantages__card'>
                  <div className='advantages__card--image'>
                    <img src="./../../img/advantages/suport.svg" alt="" />
                  </div>
                  <div className='advantages__card--title'>
                    Підтримка та Партнерство
                  </div>
                  <div className='advantages__card--text'>
                    Ми віримо в важливість підтримки наших клієнтів на кожному етапі співпраці.
                  </div>
                </div>
                <div className='advantages__card'>
                  <div className='advantages__card--image'>
                    <img src="./../../img/advantages/answer.svg" alt="" />
                  </div>
                  <div className='advantages__card--title'>
                    Юридичний супровід
                  </div>
                  <div className='advantages__card--text'>
                    Супроводжуємо клієнта при проблемних питаннях у стосунках з  учасниками енергоринку.
                  </div>
                </div>
                <div className='advantages__card'>
                  <div className='advantages__card--image'>
                    <img src="./../../img/advantages/steal.svg" alt="" />
                  </div>
                  <div className='advantages__card--title'>
                    Надійність та Стабільність
                  </div>
                  <div className='advantages__card--text'>
                    Гарантуємо стабільне та надійне постачання електроенергії.
                  </div>
                </div>
              </div>
              <Link to={'#'} className="contactAs">
                Зв'яжіться з Нами
              </Link>
            </div>
          </Row>
        </Container>

      </section>
  )
}

export default Advantages;