import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore,{ Navigation,Thumbs } from 'swiper/core';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMemo, useState } from "react";
import MonthPick from "./MonthPick";
import OperPeriodPrice from "./OperPeriodPrice";

SwiperCore.use([Thumbs]);

const OperPeriod = ({calcList, monthList, lastmonth, yearList}) => {

  let step = 1;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentYear, setCurrentYear] = useState(yearList[0]);
  const [currentMonth, setCurrentMonth] = useState('');

  const [klas, SetKlas] = useState(1);

    const currentPeriod = useMemo(()=>{
       return (currentYear + '_' + currentMonth);
    }, [currentYear, currentMonth]) 

    function handleClas(event) {
        SetKlas(parseInt(event.target.value));
    }

    function handleMonth(e){
        const pick = document.querySelector('.pick-block__label.active');
        if(pick){
            pick.classList.remove('active');
        }
        setCurrentMonth(e.target.value); 
    }

    const showPrice = useMemo(() => {
        if(!currentMonth) {
            setCurrentMonth(lastmonth.split('_')[1]);
        }
        if (calcList.length > 0) {
        return calcList.filter(calcItem => {
                    return calcItem.period === currentPeriod && calcItem.klas === klas;
                })
        }
    }, [calcList,currentPeriod,klas]);

 
  return (
    <section className="tarif-operator">
        <Container>
            <Row>
                <Col md={6}
                    onChange={handleClas}
                    >
                    <h3>2. Оберіть період тарифного плану та клас напруги</h3> 
                    <div className="oper-klas">
                      <p>Клас напруги: </p>
                      <input type="radio" value={1} name="btn-check-klas" className="circl" id="btn-check-klas100" autoComplete="off" defaultChecked />
                      <label className="radiobtn" htmlFor="btn-check-klas100">1-й клас</label>
                      <input type="radio" value={2} name="btn-check-klas" className="circl" id="btn-check-klas101" autoComplete="off"/>
                      <label className="radiobtn" htmlFor="btn-check-klas101">2-й клас</label>
                      </div>
                    </Col> 
                <Col md={6} className = 'slider-col-inner'>
                  <div className="slider-swiper-inner">
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={5}
                    slidesPerView={3}
                    onChange={(e)=>{console.log(e.target)}}
                    modules={[Navigation, Thumbs]}
                    className="swiper-thumb-date"
                >
                    {yearList.map((date,index)=>{
                        return (<SwiperSlide key={index} 
                                onChange={(e)=>{setCurrentYear(e.target.value)}}
                                className={index === 0 ? "swiper-slide-thumb-active" : ""} 
                                >

                                    <input type="radio" id={index + 'dt'} value={date} name="year" className="year__input" />
                                        <label htmlFor={index + 'dt'} className="year__label">
                                            <p>{date}</p>
                                        </label>
                                </SwiperSlide> )
                    })}
                </Swiper>
                <Swiper
                    style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                    }}
                    loop={true}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".swiper-button-prev",
                        prevEl: ".swiper-button-next",
                    }}
                    modules={[Navigation, Thumbs]}
                    grabCursor={true}
                    thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                    onSlideChange={()=> {setCurrentYear(document.querySelector('.swiper-slide-thumb-active').querySelector('.year__input').value);}}
                    className="swiper-thumb-pick"
                > 
                    {/* step-index = 12 */}
                    { 
                       yearList.map((date,index)=>{
                       step += 12;
                        return (<SwiperSlide key={index} onChange={handleMonth}>
                                    <MonthPick index={step} monthList = {monthList} lastmonth={lastmonth}/>  
                                </SwiperSlide> )
                    })}
                    </Swiper>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                  </div>   
                </Col>
            </Row>
            {!!showPrice?.length && 
                <OperPeriodPrice operator={showPrice[0].oper.name} klas={showPrice[0].klas} prices={showPrice[0]}/>
            }
        </Container>
    </section>
  )
}

export default OperPeriod