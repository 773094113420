import { useTheme } from '../hooks/useTheme';
import TitleBlock from "../components/TitleBlock";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import { debounce } from '../utils/debounce';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useRef, useState } from 'react';
import { contentPages } from "../utils/pageObj";
import { NavLink } from 'react-router-dom';

const NoPage = ({error}) => {

  const {theme, SetTheme} = useTheme();

  const [searchList, SetSearchList] = useState([]);
  const searchInput = useRef(null);
 
  function handleSearch(e) {
    if (e.target.value !== '') { 
      // getSearchPage(e.target.value).then((response)=>{
      //   const data = response['hydra:member'];
      //   const filterActive = data.filter(element => {
      //     return element.active === 'on'
      //   })
      //   SetSearchList([...filterActive, ...searchStatic]);
      // })

      const lowerValue = e.target.value.toLowerCase();
      const searchStatic = contentPages.filter(page => {
        return  page.name.toLowerCase().includes(lowerValue) || page.content.toLowerCase().includes(lowerValue);
      })

      SetSearchList(searchStatic);
    }
    else { SetSearchList(''); }
  }

    return (
        <section className='no-page'>
          <Header/>
          <div className="no-page__inner">
          <TitleBlock title={error} addClass={'error-title'}/>
            <section className="search-page">
            <Container>
              <Row><Col md={12}>
              <h3 style={{paddingBottom:'30px'}}>Спробуйте знайти інформацію в інших розділах, або скористайтесь пошуком:</h3>
              </Col></Row>
              <Row>
                  <Col md={12} className="search-page__input">
                  <input type="text" placeholder='Введіть слово для пошуку' onChange={debounce(handleSearch, 200)} ref={searchInput}/>
                  {searchList?.length > 0 &&
                      <div className="search-result">
                          {searchList.map((search, index)=>{
                          return <NavLink to={search.link || '/page/'+ search.synonym} className="search-result__item" key={index}>
                                <p>{search.name}</p>
                              </NavLink>
                          })}
                  </div>
                  }
                  </Col>
              </Row>
            </Container>
          </section>
          </div>
          <Footer setTheme = {SetTheme} theme = {theme}/>       
        </section>
    );
};

export default NoPage;