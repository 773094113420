import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'

const ComTarifPlansItem = ({nameParent, nameTariff, namePay, url}) => {

  return (
    <Row className={'tarif-plans short'}>
        <Link className="tarif-plans__inner" to={url} target={'_blank'}>
            <p className="tarif-plans__define">Комерційна пропозиція</p>
            <p className="tarif-plans__name">{nameTariff}</p>
            <p className="tarif-plans__osr active">{namePay}</p>
            <div className="tarif-plans__price">
              <p>{nameParent}</p>
                {(nameParent === 'Оплата по факту' || nameParent === 'Фіксована ціна') && <img width={'100%'} src="/img/tarif-comer1.svg" alt=""/>}
                {nameParent === 'Плановими платежами' && <img width={'100%'} src="/img/tarif-comer2.svg" alt=""/>}
                {nameParent === 'Попередня оплата' && <img width={'100%'} src="/img/tarif-comer3.svg" alt=""/>}
            </div>
            <img src="/img/tarif-comer-download.svg" className="download" alt="download"/>
        </Link>      
    </Row>
  )
}

export default ComTarifPlansItem