import React, { useState } from "react";
import TitleBlock from "../../components/TitleBlock";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import ApiForm from "../../components/ApiForm";

const Partners2 = () => {
  const [loading, SetLoading] = useState(false);

  return (
    loading
      ? <Loader />
      : <section className="page partners">
        <div className="bg_img"></div>
        <Container>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
              <div className='line__red'> </div>
              <h2 style={{ maxWidth: '772px' }} className="title">
              Як укласти договір
              </h2>
            </Col>
          </Row>
          </div>
        </Container>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <Row>
          <Col>
          <div className="asd">
              <div className="asd-11">
                <div className="asd-cont">
                <div className="asd-num">01</div>
                <div className="asd-text">
                Зверніться
                <br />
                до нас
                </div>
                </div>
                
              </div>
              <div className="asd-22">
              <div className="asd-cont">
                <div className="asd-num">02</div>
                <div className="asd-text">
                Оберіть
                <br />
                комерційну
                <br />
                пропозицію
                </div>
                </div>
              </div>
              <div className="asd-33">
              <div className="asd-cont">
                <div className="asd-num">03</div>
                <div className="asd-text">
                Підпишіть
                <br />
                заяву-
                <br />
                приєднання
                </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        </div>
        <Container>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
              <div className='line-red' style={{ marginBottom: "20px" }}></div>
              <div className="partners__contacts">
                <div className="partners__contacts--title">
                  Куди звертатись
                  <br />
                  для укладення договору?
                </div>
                <div className="partners__contacts--text">
                Для укладення договору ви можете звернутись у центральний офіс або оформити договір онлайн.
                  {/* Для укладення Договору Ви можете звернутись у Центр обслуговування клієнтів  для споживачів м. Львова (м. Львів, вул. Шевченка Т., 1), або у відповідні центри обслуговування клієнтів  у районі  для подання заяви-приєднання. */}
                </div>
                <div className="partners__contacts--subtitle">
                  Контакти
                </div>
                <div className="partners__contacts--content">
                  <div>
                    Головний офіс: 79016, м. Львів, вул. Шевченка Т., 1
                  </div>
                  <div>
                    Телефон: +38 (097) 259-55-99
                  </div>
                  <div>
                    Електронна скринька: kanc@lez-business.com
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
              <div className='line-red' style={{ marginBottom: "20px" }}></div>
              {/* <div className="partners__form--title">Зв’яжіться з нами</div> */}
              <ApiForm id={1} col={'fm-red'} />
              {/* <form action="" className="partners__form--red pForm">
                <div className="pForm__title">
                  Напишіть нам і отримайте
                  <br />
                  вигідне рішення для вашого бізнесу
                </div>
                <div className="pForm__content">
                  <div className="pForm__block-1">
                    <div className="pForm__text">
                      <div className="pForm__text--wrapper">
                        <div className="pForm__text--num">
                          01
                        </div>
                        <div className="pForm__text--text">
                          Про компанію
                        </div>
                        <div className="pForm__text--line"></div>
                      </div>
                    </div>
                    <div className="pForm__inputs">
                      <input className="pForm__input" type="text" required placeholder="Назва підприємства*" />
                      <input className="pForm__input" type="text" required placeholder="Група (А)" />
                      <input className="pForm__input" type="text" required placeholder="Оператор системи розподілу" />
                    </div>
                  </div>
                  <div className="pForm__block-2">
                    <div className="pForm__text">
                      <div className="pForm__text--wrapper">
                        <div className="pForm__text--num">
                          02
                        </div>
                        <div className="pForm__text--text">
                          Контактні данні
                        </div>
                        <div className="pForm__text--line"></div>
                      </div>
                    </div>
                    <div className="pForm__inputs">
                      <input className="pForm__input" type="text" required placeholder="Прізвище, Ім’я, По батькові*" />
                      <input className="pForm__input" type="text" required placeholder="Номер мобільного телефону*" />
                      <input className="pForm__input" type="text" required placeholder="Електронна пошта*" />
                    </div>
                  </div>
                  <div className="pForm__block-3">
                    <div className="pForm__text">
                      <div className="pForm__text--wrapper">
                        <div className="pForm__text--num pForm__text--num-2">
                          03
                        </div>
                        <div className="pForm__text--text-2">
                          Ваше повідомлення
                        </div>
                        <div className="pForm__text--none" visible="hiden"></div>
                      </div>
                    </div>
                    <div className="pForm__inputs">
                      <textarea name="asd" placeholder="Текст" cols="30" rows="10" className="pForm__textArea" style={{ resize: 'none' }} />
                    </div>
                  </div>
                  <div className="pForm__block-4">
                    <div className="pForm__footer">
                      Поле, помічене зірочкою «*»,
                      <br />
                      є обов’язковим для
                      <br />
                      заповнення
                    </div>
                    <div className="pForm__button">
                      <button type="submit">
                        Відправити
                      </button>
                    </div>
                  </div>
                </div>
              </form> */}
            </Col>
          </Row>
          </div>
        </Container>
      </section>
  )
}

export default Partners2;