import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConditionItem from "./ConditionItem";
import TitleBlock from "../TitleBlock";

const ContractConditions = ({consumer}) => {

  return (
    <section className="contract-conditions">
    <Container>
        <Row>
            <Col md={7}>
                <div className="contract-conditions__block">
                <TitleBlock subtitle={`Договірні умови електропостачання`}/>
                <ol>
                    <li>Постачання електроенергії споживачу здійснюється за договором про постачання електричної енергії споживачу обраним споживачем електропостачальником, який отримав відповідну ліцензію. </li>
                    <li>Договір між електропостачальником та споживачем укладається, як правило, шляхом приєднання споживача до розробленого електропостачальником договору на умовах комерційної пропозиції, опублікованої електропостачальником.</li>
                    <li>Споживач перед укладанням договору про постачання електричної енергії має обрати відповідну комерційну пропозицію та зазаначити її у заяві — приєднанні до умов договору постачання електричної енергії споживачу. Така комерційна пропозиція є невід’ємним додатком до договору.</li>
                </ol>
                <p></p>
                </div>
                <div className="contract-conditions__block">
                <TitleBlock subtitle={`Куди звертатись для укладення договору?`}/>
                <p>Для укладення Договору необхідно звернутись у Центр обслуговування клієнтів для споживачів м. Львова (м. Львів, вул. Шевченка, 1), або у відповідні центри обслуговування клієнтів у районі для подання заяви-приєднання.</p>
                <Col md={5}>
                    <ConditionItem 
                    url={consumer === 2 ? `/company/center-obslug` : '/clients/center-obslug'} name={"Переглянути"}
                    text = {'Центри обслуговування клієнтів'}
                    />
                </Col>
                <p></p>
                </div>
                <div className="contract-conditions__block">
                <TitleBlock 
                subtitle={`Разом із заявою-приєднанням споживач також надає`}
                type={consumer === 2 ? `для юридичних осіб та фізичних осіб-підприємців` : ''}/>
                {consumer === 2 
                ?   <>
                    <ol>
                        <li>копію документа на підтвердження повноважень особи на укладення договору (витяг з установчого документа про повноваження керівника - 
                            для юридичних осіб, копію довіреності, виданої в установленому порядку), за необхідності;</li>
                        <li>витяг або копію довідки, або копію виписки з ЄДР/ІПН/копія паспорта фізичної особи-підприємця;</li>
                        <li>копія документа, що підтверджує право власності чи користування об'єктом;</li>
                        <li>у визначених законодавством випадках - копію декларації про готовність об’єкта до експлуатації або сертифіката 
                            (для новозбудованих та реконструйованих електроустановок), копію декларації про початок виконання будівельних робіт або дозволу на виконання будівельних робіт 
                            (для укладення договору про постачання електричної енергії споживачу на будівельні майданчики);</li>
                        <li>документи, що підтверджують наявність договору, укладеного з операторам системи на послуги розподілу (передачі) електричної енергії 
                            (паспорт точки розподілу (передачі), інформацію про присвоєний ЕІС-код);</li>
                        <li>інші документи, передбачені Правилами роздрібного ринку електричної енергії.</li>
                    </ol>
                    <Col md={5}>  
                    <ConditionItem 
                     url={'/company/commercial'} name={"Переглянути"}
                     text = {'Договір, комерційні пропозиції та інші додатки'}
                     />
                    </Col>
                    </>
                    
                :  <>
                   <ol>
                    <li>довідку про присвоєння ідентифікаційного номера фізичної особи - платника податків або реєстраційний номер картки платника податків, 
                        або копію паспорта (від фізичних осіб, які через свої релігійні або інші переконання відмовляються від прийняття ідентифікаційного номера, 
                        офіційно повідомили про це відповідні органи державної влади і мають відмітку у паспорті довідка про присвоєння ідентифікаційного 
                        номеру фізичної особи - платника податків не вимагається);</li>
                    <li>копію документа, яким визначено право власності чи користування (оренда, позичка, управління тощо) на об'єкт (приміщення), або копію документа, 
                        що підтверджує право власності чи користування на земельну ділянку (у разі відсутності на відповідній земельній ділянці об'єкта);</li>
                    <li>у визначених законодавством випадках - копію декларації про готовність об’єкта до експлуатації або сертифіката (для новозбудованих та 
                        реконструйованих електроустановок), копію декларації про початок виконання будівельних робіт або дозволу на виконання будівельних робіт 
                        (для укладення договору про постачання електричної енергії споживачу на будівельні майданчики);</li>
                    <li>документи, що підтверджують наявність договору, укладеного з операторам системи на послуги розподілу (передачі) електричної енергії 
                        (паспорт точки розподілу (передачі), інформацію про присвоєний ЕІС-код);</li>
                    <li>інші документи, визначені Правилами роздрібного ринку електричної енергії.</li>
                    </ol>
                    <Row>
                        <Col md={4}>
                        <ConditionItem 
                            url={'/docx/create_doh/Dohovir_naselennia.docx'} name={"Завантажити"}
                            text = {'Договір про постачання електричної енергії постачальником універсальних послуг'}/>
                        </Col>
                        <Col md={4}>
                        <ConditionItem 
                            url={'/docx/create_doh/Dogovir_pro_kupivlyu-prodazh_elektrychnoyi_energiyi_za_zelenym_taryfom_pryvatnym_domogospodarstvom.docx'} name={"Завантажити"}
                            text = {'Договір про купівлю-продаж електричної енергії за «зеленим» тарифом приватним домогосподарством'}/>
                        </Col>
                        <Col md={4}>
                        <ConditionItem 
                            url={'/page/propozycii_universalni'} name={"Переглянути"}
                            text = {'Комерційні пропозиції'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className = 'contract-conditions__attention'>
                            <h3>Зверніть увагу!</h3>
                            <p>Під час подання документів побутовий споживач надає дозвіл на обробку, використання та зберігання 
                                його персональних даних у межах, необхідних для здійснення електропостачальником постачання електричної енергії та розрахунків за неї</p>
                        </Col>
                    </Row>
                    </>
                }
                <p></p>
                </div>
            </Col>
        </Row>
     </Container>
   </section>
  )
}

export default ContractConditions