
import TitleBlock from "../../components/TitleBlock";
import Container from 'react-bootstrap/Container';
// import SliderNews from "../../components/News/SliderNews";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Button } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { Link, useOutletContext, useParams } from 'react-router-dom';

import * as DOMPurify from 'dompurify';
import Loader from "../../components/Loader";
import { clearContent } from "../../utils/news";
import { getPage } from "../../Axios/getPage";
import PickTsok from "../../components/PickTsok";


// const DownloadButton = () => {
//   const handleDownloadClick = () => {
//     // Replace 'file_url' with the actual URL of the file you want to download
//     const fileUrl = 'https://docs.google.com/document/d/1lf4UDdJsXLwPtIawCD_zvq_nGr2CuMUq/edit';

//     // Create a hidden link
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     link.target = '_blank'; // To open the download in a new tab
//     link.download = 'звернення.pdf'; // Replace 'your_file_name' with the desired name for the downloaded file
//     link.click();
//   };

//   return (
//     <Button variant="primary" onClick={handleDownloadClick}>
//       Download File
//     </Button>
//   );
// };

const SubmissionProcedure = () => {

  const param = useParams();
  const [pageItem, SetPageItem] = useState({});
  const [loading, SetLoading] = useState(false);
  const [, , theme] = useOutletContext();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   let active = false;

  //   getPage(SetLoading, param.id).then((response) => {
  //     const [data] = response['hydra:member'];
  //     const pageContent = clearContent(data.content);
  //     const pageItem = { ...data, content: pageContent }

  //     if (!active) {
  //       SetPageItem(pageItem);
  //     }
  //   });

  //   return () => {
  //     active = true;
  //   };

  // }, [param.id])

  return (
    loading
      ? <Loader />
      : <section className="page">
        <div  className="bg_img"></div>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <TitleBlock title={`Порядок подання та розгляду електро<wbr>постачальником звернень, скарг, претензій`}></TitleBlock>
        </div>
        <Container className="page__container">
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row className="page__inner">
            <Col>
              <div className='line' style={{ marginBottom: "30px" }}></div>
              <div className="content">
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
              <p>
                Споживач у разі порушення його прав та законних інтересів першочергово має звернутись до учасника роздрібного ринку зі зверненням/скаргою/претензією щодо усунення таким учасником порушення та відновлення прав та законних інтересів споживача.
              </p>
              <p>
                До звернення/скарги/претензії споживач має додати наявні оригінали рішень або копії рішень, які приймалися за його зверненням/скаргою/претензією раніше, а також за наявності інші документи, необхідні для розгляду звернення/скарги/претензії, які після його (її) розгляду повертаються споживачу.
              </p>
              <p>
                Якщо звернення не містить даних, необхідних для прийняття обґрунтованого рішення, учасник роздрібного ринку може у строк не більше 3 календарних днів звернутися до споживача за додатковою інформацією, яка необхідна йому для повного та об’єктивного розгляду звернення/скарги/претензії.
              </p>
              <p>
                У разі ненадання споживачем необхідної інформації (документів), пояснень щодо обставин, зазначених у зверненні/скарзі/претензії, учасник роздрібного ринку має надати споживачу роз’яснення (відповідь) виходячи з наявної у нього інформації. Ненадання споживачем додаткової інформації не є підставою для відмови учасником роздрібного ринку у розгляді звернення/скарги/претензії споживача.
              </p>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"> 
          <Row>
            <Col>
              <div className='line' style={{ marginBottom: "30px" }}></div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Розгляд письмових звернень громадян
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"> 
          <Row>
            <Col>
              <ol style={{ marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Вимоги до звернень.</strong>
                  <ol style={{ listStyle: "none", marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      1.1 Звернення адресуються органам державної влади і органам місцевого самоврядування, підприємствам, установам, організаціям незалежно від форми власності, об'єднанням громадян або посадовим особам, до повноважень яких належить вирішення порушених у зверненнях питань.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      1.2 Звернення може бути подано окремою особою (індивідуальне) або групою осіб (колективне). Звернення може бути усним чи письмовим. Усне звернення викладається громадянином на особистому прийомі або за допомогою засобів телефонного зв'язку через визначені контактні центри, телефонні "гарячі лінії" та записується (реєструється) посадовою особою.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      1.3 Письмове звернення надсилається поштою або передається громадянином до відповідного органу, установи особисто чи через уповноважену ним особу, повноваження якої оформлені відповідно до законодавства. Письмове звернення також може бути надіслане з використанням мережі Інтернет, засобів електронного зв'язку (електронне звернення).
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      1.4 У зверненні має бути зазначено прізвище, ім'я, по батькові, місце проживання громадянина, викладено суть порушеного питання, зауваження, пропозиції, заяви чи скарги, прохання чи вимоги. Письмове звернення повинно бути підписано заявником (заявниками) із зазначенням дати. В електронному зверненні також має бути зазначено електронну поштову адресу, на яку заявнику може бути надіслано відповідь, або відомості про інші засоби зв'язку з ним. Застосування електронного цифрового підпису при надсиланні електронного звернення не вимагається.
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Заборона відмови в прийнятті та розгляді звернення</strong>
                  <ol style={{ listStyle: "none", marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      2.1. Звернення, оформлені належним чином і подані у встановленому порядку, підлягають обов'язковому прийняттю та розгляду.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      2.2. Забороняється відмова в прийнятті та розгляді звернення з посиланням на політичні погляди, партійну належність, стать, вік, віросповідання, національність громадянина, незнання мови звернення.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      2.3. Якщо питання, порушені в одержаному органом державної влади, місцевого самоврядування, підприємствами, установами, організаціями незалежно від форм власності, об'єднаннями громадян або посадовими особами зверненні, не входять до їх повноважень, воно в термін не більше п'яти днів пересилається ними за належністю відповідному органу чи посадовій особі, про що повідомляється громадянину, який подав звернення. У разі якщо звернення не містить даних, необхідних для прийняття обгрунтованого рішення органом чи посадовою особою, воно в той же термін повертається громадянину з відповідними роз'ясненнями.
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Безоплатність розгляду звернення</strong>
                  <ol style={{ listStyle: "none", marginBottom: "10px" }}>
                    <li>
                      3.1 Органи державної влади, місцевого самоврядування, підприємства, установи, організації незалежно від форм власності, об'єднання громадян, посадові особи розглядають звернення громадян, не стягуючи плати.
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Терміни розгляду звернення</strong>
                  <ol style={{ listStyle: "none", marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      <p>
                        4.1. Звернення розглядаються і вирішуються у термін не більше одного місяця від дня їх надходження, а ті, які не потребують додаткового вивчення, - невідкладно, але не пізніше п'ятнадцяти днів від дня їх отримання. Якщо в місячний термін вирішити порушені у зверненні питання неможливо, керівник відповідного органу, підприємства, установи, організації або його заступник встановлюють необхідний термін для його розгляду, про що повідомляється особі, яка подала звернення. При цьому загальний термін вирішення питань, порушених у зверненні, не може перевищувати сорока п'яти днів.
                      </p>
                      <p>
                        Звернення споживачів щодо перевірки правильності рахунку на оплату розглядаються у термін 5 робочих днів з дати отримання звернення.
                      </p>
                      <p>
                        Якщо під час розгляду звернення/скарги/претензії необхідно здійснити технічну перевірку або провести експертизу засобу обліку, то такі звернення/скарги/претензії розглядаються у термін 45 днів.
                      </p>
                      <p>
                        Дані про споживання електричної енергії надаються споживачу у термін 5 робочих днів з дня отримання запиту.
                      </p>
                      <p>
                        Звернення (скарги/претензії) побутових споживачів, які мають встановлені законодавством пільги, розглядаються першочергово.
                      </p>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      4.2. На обгрунтовану письмову вимогу громадянина термін розгляду може бути скорочено від встановленого цією статтею терміну.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      4.3. Звернення громадян, які мають встановлені законодавством пільги, розглядаються у першочерговому порядку.
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Звернення, які не підлягають розгляду та вирішенню</strong>
                  <ol style={{ listStyle: "none", marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      5.1. Письмове звернення без зазначення місця проживання, не підписане автором (авторами), а також таке, з якого неможливо встановити авторство, визнається анонімним і розгляду не підлягає.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      5.2. Не розглядаються повторні звернення одним і тим же органом від одного і того ж громадянина з одного і того ж питання, якщо перше вирішено по суті, а також ті звернення, терміни розгляду яких передбачено статтею 17 цього Закону, та звернення осіб, визнаних судом недієздатними. Рішення про припинення розгляду такого звернення приймає керівник органу, про що повідомляється особі, яка подала звернення.
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                Контакти для подання звернень, скарг, претензій дивіться за посиланням:&nbsp;
                <Link to="/comunication" target="_blank">
                  Перейти
                </Link>
                <p>
                  Окрім цього, пропонуємо Вам скористатись мобільним додатком «Енергетика Онлайн», розробленим НКРЕКП.
                </p>
              </p>

              <p style={{ fontWeight: "600" }}>
                Завантажити додаток можна за посиланням:&nbsp;
                <Link to="https://play.google.com/store/apps/details?id=com.energyonline&pli=1" target="_blank">
                  Завантажити
                </Link>
              </p>

              <p style={{ fontWeight: "600" }}>
                Інструкція про подання звернень/скарг/претензій та процедура розгляду звернень/скарг/претензій
              </p>
              <p>
                Згідно зі статтею 58 Закону України «Про ринок електричної енергії» передбачено право споживачів на отримання електричної енергії належної якості згідно з умовами договору та стандартами якості електричної енергії.
              </p>
              <p>
                Порядок забезпечення стандартів якості надання послуг з електропостачання затверджений постановою НКРЕКП від 12.06.2018 року № 375.
              </p>
              <p>
                Споживач може подати звернення, скарги та претензію письмово на адресу ТОВ «ЛЕЗ Бізнес»   або будь-яким іншим зручним способом з дотриманням вимог передбачених чинним законодавством.
              </p>
              <p>
                При розгляді звернень ТОВ «ЛЕЗ Бізнес» керується зокрема, але не виключно нижченаведеними нормативними документами:
              </p>
              <ul>
                <li>
                  Закон України «Про ринок електричної енергії»;
                </li>
                <li>
                  Закон України «Про звернення громадян»;
                </li>
                <li>
                  Закон України «Про інформацію»;
                </li>
                <li>
                  Правила роздрібного ринку електричної енергії;
                </li>
                <li>
                  Порядок забезпечення стандартів якості надання послуг з електропостачання;
                </li>
                <li>
                  Положення про Інформаційно-консультаційний центр по роботі із споживачами  електричної енергії , затверджене постановою НКРЕКП від 12 березня 2009 року №299 зареєстрованим в Міністерстві юстиції України 06 квітня 2009 р. за № 308/16324.
                </li>
              </ul>
              <p>
                За результатом розгляду звернення Вам буде надано відповідь.
              </p>
              <p>
                У випадку незгодою з відповіддю, суперечки можуть бути передані на вирішення в суд відповідно до підсудності та підвідомчості, встановленої чинним законодавством України та розглядатись відповідно до чинного законодавства України.
              </p>
              <Link to="https://dev-api-lez.inneti.net/uploads/business/appeal.pdf" className="btn-yellow-white" target="_blank" style={{ color: "#fff", marginBottom: "50px" }} download>Приклад Звернення <br />
                (зауваження, пропозиції, заява, скарга, прохання, вимога, претензія)
              </Link> 
              {/* <DownloadButton /> */}
            </Col>
          </Row>
          </div>
        </Container>
        {/* <SliderNews /> */}
      </section>
  )
}

export default SubmissionProcedure
