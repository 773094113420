import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';

const AccordionInfo = () => {
  const [infoList, SetInfoList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // code for get request
    const exampleList = [
      { id: 1, title: 'Загальні умови електропостачання', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 2, title: 'Порядок зміни електропостачальника', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 3, title: 'Пільги та субсидії', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 4, title: 'Порядок подання та розгляду електропостачальником звернень, скарг, претензій', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 5, title: 'Права споживачів', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 6, title: 'Інформація щодо установ, з якими ТОВ «Львівенергозбут» уклало договори про прийом платежів від побутових споживачів (населення) за спожиту електричну енергію', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 7, title: 'Енергоефективність', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 8, title: 'Показники  якості надання послуг з електропостачання', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 9, title: 'Порядок зняття показів засобів вимірювання та оплати спожитої електричної енергії', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
      { id: 10, title: 'Вплив на довкілля, спричинений виробництвом електричної енергії', content: ['ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році', 'ТОВ «Львівенергозбут» надає інформацію  щодо частки кожного джерела енергії у загальній структурі обсягу купленої електричної енергії за обсягом виробництва та вартості у 2020 році'] },
    ]
    SetInfoList(exampleList);
  }, [])

  return (
    <Container className='accordion-info'>
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            {infoList.map((infoItem, index) => {
              return <Accordion.Item key={infoItem.id} eventKey={index}>
                <Accordion.Header><span>{index < 9 ? `0${index + 1}` : index + 1}</span> {infoItem.title}</Accordion.Header>
                <Accordion.Body>
                  {infoItem.content.map((content, index) => {
                    return <p key={index}>{content}</p>
                  })}
                </Accordion.Body>
              </Accordion.Item>
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default AccordionInfo