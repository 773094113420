import React, { useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react';
import SliderNews from '../News/SliderNews';

function MainSlide({ handleActive }) {

  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    handleActive(swiperSlide.isActive)
  }, [swiperSlide.isActive, handleActive])

  return (
    <>
      <h1>
        <span className="main-slide__electro">
          Світло і газ<br />
          доступно у нас!
        </span>

      </h1>
    </>
  )
}

export default MainSlide
