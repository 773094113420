
const Offline = () => {
    return (
        <>
            <p id="NotNetwork"> Нема з`єднання з мережею!</p>
            <style>
                { "footer {display: none;} .prefooter {display: none;}" }
            </style>
        </>
    );
}

export default  Offline;
