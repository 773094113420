
import TitleBlock from "../../components/TitleBlock";
import Container from 'react-bootstrap/Container';
// import SliderNews from "../../components/News/SliderNews";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react";
import { Link, useOutletContext, useParams } from 'react-router-dom';

import * as DOMPurify from 'dompurify';
import Loader from "../../components/Loader";
import { clearContent } from "../../utils/news";
import { getPage } from "../../Axios/getPage";
import PickTsok from "../../components/PickTsok";

const Gas = () => {

  const param = useParams();
  const [pageItem, SetPageItem] = useState({});
  const [loading, SetLoading] = useState(false);
  const [, , theme] = useOutletContext();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   let active = false;

  //   getPage(SetLoading, param.id).then((response) => {
  //     const [data] = response['hydra:member'];
  //     const pageContent = clearContent(data.content);
  //     const pageItem = { ...data, content: pageContent }

  //     if (!active) {
  //       SetPageItem(pageItem);
  //     }
  //   });

  //   return () => {
  //     active = true;
  //   };

  // }, [param.id])

  return (
    loading
      ? <Loader />
      : <section className="page">
        <div className="bg_img"></div>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <TitleBlock title={'Постачання газу'} color={'red'}></TitleBlock>
        </div>
        <Container className="page__container">
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
              <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Нормативні документи (Постачання природного газу)
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
              <ul style={{ listStyle: "none", marginBottom: "50px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/329-19#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    Закон України "Про ринок природного газу" (від 09.04.2015 № 329-VIII)
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1382-15#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    "Правила постачання природного газу" (постанова НКРЕКП від 30.09.2015 № 2496)
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1379-15#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    "Кодекс газорозподільних систем"  (постанова НКРЕКП від 30.09.2015  № 2494)
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1378-15#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    "Кодекс газотранспортної системи"  (постанова НКРЕКП від 30.09.2015  № 2493)
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1380-15#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    "Кодекс газосховищ" (постанова НКРЕКП від 30.09.2015 № 2495)
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    Мінімальні стандарти та вимоги до якості обслуговування споживачів та постачання природного газу
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/2657-12#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    ЗУ «Про інформацію»
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/1540-19#Text" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    ЗУ «Про національну комісію, що здійснює державне регулювання у сферах
                    енергетики та комунальних послуг»
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to="https://zakon.rada.gov.ua/laws/show/v0201874-17#n751" style={{ fontWeight: "500", fontSize: "20px", marginBottom: "10px" }} target="_blank">
                    Ліцензійні умови провадження господарської діяльності з постачання природного газу
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Загальні умови постачання
              </div>
              <Link to="https://dev-api-lez.inneti.net/uploads/business/%D0%97%D0%B0%D0%B3%20%D1%83%D0%BC%D0%BE%D0%B2%D0%B8%20%D0%BF%D0%BE%D1%81%D1%82%D0%B0%D1%87%D0%B0%D0%BD%D0%BD%D1%8F.docx.pdf" target="_blank">
                ЗАВАНТАЖИТИ
              </Link>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Договір постачання природного газу для потреб непобутових споживачів
              </div>
              <Link to="https://dev-api-lez.inneti.net/uploads/business/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D1%96%D1%80_%D0%BF%D1%80%D0%BE_%D0%BF%D0%BE%D1%81%D1%82%D0%B0%D1%87%D0%B0%D0%BD%D0%BD%D1%8F_%D0%BF%D1%80%D0%B8%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE_%D0%B3%D0%B0%D0%B7%D1%83_docx.pdf" target="_blank">
                ЗАВАНТАЖИТИ ДОГОВІР
              </Link>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Права та обов'язки постачальника і споживача природного газу
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
              <p>
                Постачальник має право:
              </p>
              <ul>
                <li style={{ marginBottom: "10px" }}>
                  укласти договір постачання природного газу з будь-яким споживачем та за відсутності простроченої заборгованості споживача за природний газ перед діючим постачальником, що підтверджується письмовою довідкою діючого постачальника або складеним з ним актом звірки взаєморозрахунків, або за наявності письмового дозволу діючого постачальника на перехід споживача до нового постачальника поставити природний газ споживачу в періоді, наступному
                </li>
                <li style={{ marginBottom: "10px" }}>
                  отримувати від споживача своєчасну оплату за природний газ відповідно до умов договору;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  на безперешкодний доступ (за пред'явленням службового посвідчення) до комерційних вузлів обліку природного газу, що встановлені на об'єктах газоспоживання споживача, для звірки даних фактичного споживання природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  на повну і достовірну інформацію від споживача, з яким укладено договір, щодо режимів споживання природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  на ініціювання процедури припинення (обмеження) постачання природного газу споживачу згідно з умовами договору на постачання природного газу та відповідно до вимог цих Правил;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  інші права, передбачені цими Правилами та чинним законодавством.
                </li>
              </ul>
              <p>
                Постачальник зобов'язаний:
              </p>
              <ul>
                <li style={{ marginBottom: "10px" }}>
                  дотримуватись вимог цих Правил;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  забезпечувати постачання природного газу на умовах, визначених договором постачання природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  забезпечувати відповідно до вимог &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1378-15#n18" target="_blank">
                    Кодексу газотранспортної системи &nbsp;
                  </Link>
                  своєчасну реєстрацію споживача у власному Реєстрі споживачів на інформаційній платформі Оператора ГТС за умови дотримання споживачем укладеного із постачальником договору постачання природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  в установленому порядку розглядати запити споживачів щодо діяльності, пов'язаної з постачанням природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  своєчасно надавати споживачу достовірну інформацію, у тому числі передбачену&nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/887-19" target="_blank">
                    Законом України
                  </Link>
                  &nbsp;
                  «Про особливості доступу до інформації у сферах постачання електричної енергії, природного газу, теплопостачання, централізованого постачання гарячої води, централізованого питного водопостачання та водовідведення», а також дані про фактичні нарахування (обсяг та вартість) за послуги з газопостачання, щоб дати можливість споживачеві регулювати власне споживання природного газу. Заходи щодо надання інформації для споживача мають бути передбачені у договорі постачання природного газу. Жодні додаткові витрати за надання інформації споживачем не оплачуються;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  своєчасно повідомляти споживача, якщо постачальник перебуває у процесі ліквідації, або визнання банкрутом, або постачальник проходить по процедурі призупинення/анулювання ліцензії на постачання природного газу, або його ліцензію на провадження діяльності з постачання природного газу анульовано, або її дію зупинено та про відсутність ресурсу природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  дотримуватися мінімальних стандартів та вимог до якості обслуговування споживачів природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  пропонувати споживачу за договором постачання природного газу вибір способів оплати за природний газ, що постачається і споживається, включаючи банківський платіжний сервіс, онлайн-переказ, поштовий переказ, внесення готівки на рахунок постачальника в касі та/або інші методи, погодження яких має бути справедливим, прозорим і недискримінаційним між споживачами;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  створити точки контакту для надання інформації споживачам. Контактні дані і режим роботи кожної контактної точки повинні бути передбачені у договорі постачання природного газу і на веб-сайті постачальника у мережі Інтернет;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  надати споживачеві остаточний рахунок (рахунок-фактуру) після зміни постачальника або розірвання договору постачання природного газу не пізніше ніж через шість тижнів після такої зміни або розірвання договору;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  у випадку ініціювання  виключення споживача із Реєстру споживачів постачальника своєчасно (одночасно з наданням повідомлення Оператору ГТС відповідно до вимог&nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1378-15#n18" target="_blank">
                    Кодексу газотранспортної системи
                  </Link>
                  ) повідомляти споживача про вчинення таких дій;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  укласти договір припинення (обмеження) постачання природного газу з Оператором ГРМ, у газорозподільній зоні якого розташований споживач такого постачальника;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  виконувати інші обов'язки, передбачені цими Правилами та чинним законодавством.
                </li>
              </ul>
              <p>
                Споживач зобов'язується:
              </p>
              <ul>
                <li style={{ marginBottom: "10px" }}>
                  дотримуватись вимог цих Правил;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  забезпечувати дотримання дисципліни відбору (споживання) природного газу в обсягах та на умовах, визначених договорами;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  своєчасно та в повному обсязі сплачувати за поставлений природний газ на умовах, визначених договорами;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  здійснювати комплекс заходів, спрямованих на запобігання виникненню загрози життю або травматизму, пошкодженню обладнання та продукції, негативних екологічних наслідків тощо в разі отримання повідомлення про припинення (обмеження) постачання (розподілу/транспортування) природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  забезпечувати допуск представників постачальника за пред'явленням службового посвідчення на територію власних об'єктів для звірки даних фактичного споживання природного газу;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  самостійно обмежувати (припиняти) споживання природного газу у випадках:
                  <ul style={{ marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      порушення строків оплати за договором на постачання природного газу;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      відсутності споживача у розрахунковому періоді в Реєстрі будь-якого постачальника на інформаційній платформі Оператора ГТС;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      перевищення підтвердженого обсягу природного газу без узгодження з постачальником;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      відсутності укладеного договору постачання природного газу;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      інших випадках, передбачених цими Правилами та чинним законодавством.
                    </li>
                  </ul>
                </li>
              </ul>
              <p style={{ marginBottom: "10px" }}>
                Споживач має право:
              </p>
              <ul>
                <ul style={{ marginBottom: "10px" }}>
                  <li style={{ marginBottom: "10px" }}>
                    на отримання природного газу в обсягах, визначених договором постачання природного газу, за умови дотримання його умов;
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    на одночасне отримання природного газу від декількох постачальників на одну точку комерційного обліку, якій присвоєно окремий EIC-код, в одному розрахунковому періоді в порядку, встановленому пунктом 22 цього розділу&nbsp;
                    <Link to="https://zakon.rada.gov.ua/laws/show/z1382-15#n444" target="_blank">
                    </Link>
                    &nbsp;
                    посилання, за умови укладення договору постачання природного газу з такими постачальниками та після укладення з ними угоди про алокацію відповідно до вимог&nbsp;
                    <Link to="https://zakon.rada.gov.ua/laws/show/z1378-15#n18" target="_blank">
                      Кодексу газотранспортної системи;
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    на безкоштовне отримання інформації щодо цін постачальника на природний газ та порядку оплати;
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    самостійно припиняти (обмежувати) відбір природного газу для власних потреб з дотриманням вимог чинного законодавства, про що повинен письмово повідомляти всіх суб'єктів ринку природного газу, з якими укладено відповідні договори;
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    вимагати поновлення постачання природного газу в установленому порядку після усунення порушень і компенсації оплати послуг за відключення та підключення, якщо припинення газопостачання відбулося без розірвання договору постачання природного газу;
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    інші права, передбачені цими Правилами та чинним законодавством.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Споживач, комерційний ВОГ якого віднесено до І категорії (річний обсяг споживання становить більше 3 млн куб. м), має право на одночасне отримання природного газу від декількох постачальників на одну точку комерційного обліку, якій присвоєно окремий EIC-код, в одному розрахунковому періоді після укладення з такими постачальниками угоди про алокацію, предметом якої є розподілення об’єму фізичного відбору споживача на відбори кожного постачальника (замовника послуг транспортування), який є стороною цієї угоди.
                  </li>
                </ul>
                <li style={{ marginBottom: "10px" }}>
                  Угода про алокацію повинна містити такі умови, що є істотними та обов'язковими для цього виду угоди:
                  <ol style={{ marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      місце і дату укладення угоди, а також найменування постачальників і споживача та їх EIC-коди як суб'єктів ринку природного газу;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      предмет угоди, яким є розподілення обсягів постачання споживачу природного газу між постачальниками;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      ЕІС-код точки комерційного обліку споживача, по якій буде здійснюватися постачання природного газу постачальниками;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      порядок пропорційного розподілення (алокаційний алгоритм) фізичного відбору споживача на відбори кожного постачальника за кожну газову добу;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      період постачання природного газу, на який розповсюджується дія алокаційного алгоритму;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      умови припинення угоди про алокацію.
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Постачання газу споживачу на умовах угоди про алокацію здійснюється за умови реєстрації угоди про алокацію в інформаційній платформі Оператора ГТС в порядку, встановленому&nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/z1378-15#n18" target="_blank">
                    Кодексом газотранспортної системи.
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  23. Спірні питання між споживачем і постачальником щодо постачання природного газу, оплати послуг постачальника, відшкодування збитків тощо мають вирішуватися шляхом переговорів, а у разі недосягнення згоди - у судовому порядку.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Споживач та постачальник не обмежуються в праві звернутися до Регулятора чи його територіальних підрозділів за відповідними роз’ясненнями щодо застосування цих Правил та правовідносин на ринку природного газу.
                </li>
              </ul>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Порядок зміни постачальника, що не є побутовим
              </div>
              <Link to="https://zakon.rada.gov.ua/laws/show/z1382-15#n267" target="_blank">
                ПОСИЛАННЯ
              </Link>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Порядок подання та розгляду постачальником природного газу звернень, скарг, претензій
              </div>
              <p>
                Споживач у разі порушення його прав та законних інтересів першочергово має звернутись до постачальника природного газу зі зверненням/скаргою/претензією щодо усунення таким порушення та відновлення прав та законних інтересів споживача.
              </p>
              <p>

                До звернення/скарги/претензії споживач має додати наявні оригінали рішень або копії рішень, які приймалися за його зверненням/скаргою/претензією раніше, а також за наявності інші документи, необхідні для розгляду звернення/скарги/претензії, які після його (її) розгляду повертаються споживачу.
              </p>
              <p>
                Якщо звернення не містить даних, необхідних для прийняття обґрунтованого рішення, постачальником природного газу може у строк не більше 3 календарних днів звернутися до споживача за додатковою інформацією, яка необхідна йому для повного та об’єктивного розгляду звернення/скарги/претензії.
              </p>
              <p>
                У разі ненадання споживачем необхідної інформації (документів), пояснень щодо обставин, зазначених у зверненні/скарзі/претензії, постачальником природного газу має надати споживачу роз’яснення (відповідь) виходячи з наявної у нього інформації. Ненадання споживачем додаткової інформації не є підставою для відмови у розгляді звернення/скарги/претензії споживача.
              </p>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
            <div className='line__red' style={{ marginBottom: "30px" }}> </div>
              <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px" }}>
                Розгляд письмових звернень громадян
              </div>
            </Col>
          </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row style={{ marginBottom: "50px" }}>
            <Col>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <p style={{ marginBottom: "10px" }}>
                    ВИМОГИ ДО ЗВЕРНЕННЯ.
                  </p>
                  <p>
                    Звернення адресуються органам державної влади і органам місцевого самоврядування, підприємствам, установам, організаціям незалежно від форми власності, об’єднанням громадян або посадовим особам, до повноважень яких належить вирішення порушених у зверненнях питань.
                  </p>
                  <p>
                    Звернення може бути подано окремою особою (індивідуальне) або групою осіб (колективне).
                  </p>
                  <p>
                    Звернення може бути усним чи письмовим.
                  </p>
                  <p>
                    Усне звернення викладається громадянином на особистому прийомі або за допомогою засобів телефонного зв’язку через визначені контактні центри, телефонні "гарячі лінії" та записується (реєструється) посадовою особою.
                  </p>
                  <p>
                    Письмове звернення надсилається поштою або передається громадянином до відповідного органу, установи особисто чи через уповноважену ним особу, повноваження якої оформлені відповідно до законодавства. Письмове звернення також може бути надіслане з використанням мережі Інтернет, засобів електронного зв’язку (електронне звернення)
                  </p>
                  <p>
                    У зверненні має бути зазначено прізвище, ім’я, по батькові, місце проживання громадянина, викладено суть порушеного питання, зауваження, пропозиції, заяви чи скарги, прохання чи вимоги. Письмове звернення повинно бути підписано заявником (заявниками) із зазначенням дати. В електронному зверненні також має бути зазначено електронну поштову адресу, на яку заявнику може бути надіслано відповідь, або відомості про інші засоби зв’язку з ним. Застосування електронного цифрового підпису при надсиланні електронного звернення не вимагається.
                  </p>
                  <p>
                    Звернення, оформлене без дотримання зазначених вимог, повертається заявнику з відповідними роз’ясненнями не пізніш як через десять днів від дня його надходження, крім випадків, передбачених частиною першою статті 7 ЗаконуУкраїни «Про звернення громадян».
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p>
                    ЗАБОРОНА ВІДМОВИ В ПРИЙНЯТТІ ТА РОЗГЛЯДІ ЗВЕРНЕННЯ.
                  </p>
                  <p>
                    Звернення, оформлені належним чином і подані у встановленому порядку, підлягають обов'язковому прийняттю та розгляду.
                  </p>
                  <p>
                    Забороняється відмова в прийнятті та розгляді звернення з посиланням на політичні погляди, партійну належність, стать, вік, віросповідання, національність громадянина, незнання мови звернення.
                  </p>
                  <p>
                    Якщо питання, порушені в одержаному органом державної влади, місцевого самоврядування, підприємствами, установами, організаціями незалежно від форм власності, об'єднаннями громадян або посадовими особами зверненні, не входять до їх повноважень, воно в термін не більше п'яти днів пересилається ними за належністю відповідному органу чи посадовій особі, про що повідомляється громадянину, який подав звернення. У разі якщо звернення не містить даних, необхідних для прийняття обґрунтованого рішення органом чи посадовою особою, воно в той же термін повертається громадянину з відповідними роз'ясненнями.
                  </p>
                  <p>
                    Забороняється направляти скарги громадян для розгляду тим органам або посадовим особам, дії чи рішення яких оскаржуються.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p>
                    БЕЗОПЛАТНІСТЬ РОЗГЛЯДУ ЗВЕРНЕННЯ.
                  </p>
                  <p>
                    Органи державної влади, місцевого самоврядування, підприємства, установи, організації незалежно від форм власності, об'єднання громадян, посадові особи розглядають звернення громадян, не стягуючи плати.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p>
                    ТЕРМІНИ РОЗГЛЯДУ ЗВЕРНЕННЯ.
                  </p>
                  <p>
                    Звернення розглядаються і вирішуються у термін не більше одного місяця від дня їх надходження, а ті, які не потребують додаткового вивчення, - невідкладно, але не пізніше п'ятнадцяти днів від дня їх отримання. Якщо в місячний термін вирішити порушені у зверненні питання неможливо, керівник відповідного органу, підприємства, установи, організації або його заступник встановлюють необхідний термін для його розгляду, про що повідомляється особі, яка подала звернення. При цьому загальний термін вирішення питань, порушених у зверненні, не може перевищувати сорока п'яти днів.
                  </p>
                  <p>
                    На обґрунтовану письмову вимогу громадянина термін розгляду може бути скорочено від встановленого цією статтею терміну.
                  </p>
                  <p>
                    Звернення громадян, які мають встановлені законодавством пільги, розглядаються у першочерговому порядку.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p>
                    ЗВЕРНЕННЯ, ЯКІ НЕ ПІДЛЯГАЮТЬ РОЗГЛЯДУ ТА ВИРІШЕННЮ.
                  </p>
                  <p>
                    Письмове звернення без зазначення місця проживання, не підписане автором (авторами), а також таке, з якого неможливо встановити авторство, визнається анонімним і розгляду не підлягає.
                  </p>
                  <p>
                    Не розглядаються повторні звернення одним і тим же органом від одного і того ж громадянина з одного і того ж питання, якщо перше вирішено по суті, а також ті звернення, терміни розгляду яких передбачено статтею 17 цього Закону, та звернення осіб, визнаних судом недієздатними.
                  </p>
                  <p>
                    Рішення про припинення розгляду такого звернення приймає керівник органу, про щоповідомляється особі, яка подала звернення.
                  </p>
                </li>
              </ol>

              <p>
                Контакти ТОВ "ЛЕЗ Бізнес" для подання звернень, скарг, претензій дивіться за посиланням:
                &nbsp;
                <Link to="/comunication" target="_blank">
                  ПОСИЛАННЯ
                </Link>
              </p>
              <p>
                Окрім цього, пропонуємо Вам скористатись мобільним додатком «Енергетика Онлайн», розробленим НКРЕКП. Завантажити додаток можна за посиланням:
              </p>
              <p>
                Енергетика Онлайн для IOS
              </p>
              <p>
                <Link to="https://itunes.apple.com/ua/app/energyonline/id1441399796?l=uk&mt=8" target="_blank">
                  Завантажити
                </Link>
              </p>
              <p>
                Енергетика Онлайн для Android
              </p>
              <p>
                <Link to="https://play.google.com/store/apps/details?id=com.energyonline" target="_blank">
                  Завантажити
                </Link>
              </p>
              <br />
              <br />
              <br />

              <p>
                <strong style={{ fontSize: '32px', fontWeight: '700' }}>
                  Вирішення спорів
                </strong>
              </p>
              <p>
                Процедура вирішення спорів постачальником природного газу ТОВ «ЛЕЗ Бізнес», контактна інформація підрозділу ТОВ «ЛЕЗ Бізнес», відповідального за розв’язання спорів
              </p>
              <p>
                Всі спори, що виникають між постачальником природного газу та споживачем вирішуються шляхом досудового врегулювання спорів у прозорий, справедливий і швидкий спосіб. Постачальник зобов'язаний розглянути всі скарги, отримані від споживачів, і протягом одного місяця повідомити про результати їх розгляду.
              </p>
              {/* <p>
                Інформація про структурні підрозділи/працівників ТОВ «ЛЕЗ Бізнес», які відповідальні за розв’язання спорів із споживачами природного газу:
              </p> */}
              <ul style={{ marginBottom: "10px" }}>
                <li style={{ marginBottom: "10px" }}>
                  Адреса: 79016, м.Львів, вул. Шевченка Т., 1
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Листування здійснюється за адресою: 79016, м.Львів, вул. Шевченка Т., 
                </li>
                <p style={{ marginBottom: "10px" }}>
                  Відповідальна особа за вирішення спорів, розгляд скарг споживачів та проведення переговорів:
                </p>
                <p style={{ marginBottom: "10px" }}>
                  Олена Овчиннікова.
                </p>
                <li style={{ marginBottom: "10px" }}>
                  Режим роботи: понеділок - четвер: з 8:00 до 17:00 год., п’ятниця: з 8:00 до 16:00 год. Вихідні: субота та неділя.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Телефон: +38 (097) 259-55-99
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Електронна скринька: lez.business.ua@gmail.com
                </li>
              </ul>
              <br />
              <br />
              <br />
              <p>
                У разі невирішення Вашого питання шляхом переговорів, незадоволення наслідками усного розгляду або небажання вести переговори усним шляхом, звертайтесь до ТОВ «ЛЕЗ Бізнес» із претензією.
              </p>
              <p>
                Згідно вимог ст. 222 Господарського кодексу України у претензії зазначаються:
              </p>
              <ul>
                <li style={{ marginBottom: "10px" }}>
                  повне найменування і поштові реквізити заявника претензії та особи (осіб), якій претензія пред'являється;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  дата пред'явлення і номер претензії;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  обставини, на підставі яких пред'явлено претензію;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  докази, що підтверджують ці обставини;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  вимоги заявника з посиланням на нормативні акти;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  сума претензії та її розрахунок, якщо претензія підлягає грошовій оцінці;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  платіжні реквізити заявника претензії;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  перелік документів, що додаються до претензії.
                </li>
              </ul>
              <p>
                Документи, що підтверджують вимоги заявника, додаються в оригіналах чи належним чином засвідчених копіях. Документи, які є у другої сторони, можуть не додаватися до претензії.
              </p>
              <p>
                Претензія підписується повноважною особою заявника претензії або його представником та надсилається адресатові рекомендованим або цінним листом або вручається адресатові під розписку, або відправляється електронною поштою.
              </p>
              <p>
                Претензія розглядається якнайшвидше, але не пізніше 1 місяця з дня її одержання.
              </p>
              <p>
                У разі невирішення Вашого питання шляхом проведення переговорів та/або в претензійному порядку, Ви можете звернутись до Національної комісії, що здійснює державне регулювання у сферах енергетики та комунальних послуг (НКРЕКП): 03057, м. Київ, вул. Сім’ї Бродських, 19, тел. (044) 204-70-72; e-mail: box@nerc.gov.ua, офіційний інтернет-сайт НКРЕКП: http://www.nerc.gov.ua/ або до Відділу НКРЕКП у Львівській області:, 79071,
              </p>
              <p>
                м. Львів, пров. 1-й Скнилівський, 10а, е-mail: lviv@nerc.gov.ua
              </p>
              <p>
                Звернення до НКРЕКП чи Відділу НКРЕКП у Львівській області не позбавляє Вас права на вирішення спору в судовому порядку.
              </p>
              <p>
                <Link to="https://zakon.rada.gov.ua/laws/show/z1382-15#n303" target="_blank">
                  <strong>
                    Порядок відшкодування збитків та вирішення спорів
                  </strong>
                </Link>
              </p>
              <p>
                Постанова НКРЕКП № 1156 від 21.09.2017р. «Про затвердження Мінімальних стандартів та вимог до якості обслуговування споживачів та постачання природного газу» (із змінами та доповненнями)
              </p>
              <p>
                <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17?find=1&text=%D1%81%D0%B0%D0%B9%D1%82#n10" target="_blank">
                  <strong>
                    ПОСИЛАННЯ
                  </strong>
                </Link>
              </p>
              <br />
              <br />
              <br />
              <p>
                <strong style={{ fontSize: '32px', fontWeight: '700' }}>
                  Мінімальні стандарти якості послуг постачання природного газу
                </strong>
              </p>
              <p>
                До мінімальних стандартів якості при наданні постачальником послуг постачання природного газу належать:
              </p>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  надання письмової форми договору постачання природного газу, підписаного уповноваженою особою постачальника, на вимогу побутового споживача - у строк не більше 10 робочих днів з дати отримання відповідного письмового звернення побутового споживача;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  надання повідомлення про намір змінити умови договору постачання природного газу для непобутових споживачів - не пізніше ніж за 30 днів до запланованого набрання чинності такими змінами (крім ціни на природний газ, якщо вона встановлюється для постачальника відповідними державними органами);
                </li>
                <li style={{ marginBottom: "10px" }}>
                  повернення переплати вартості природного газу на поточний рахунок побутового споживача, у тому числі повернення переплати попередніми постачальниками, або надання обґрунтованої відповіді споживачу щодо повернення суми переплати - у строк не більше 5 робочих днів з дня отримання письмової вимоги побутового споживача;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  надання повідомлення побутовому споживачу про коригування персоніфікованих даних споживача, що зазначені у договорі постачання природного газу, у строк 10 робочих днів з дня реєстрації відповідної заяви споживача про внесення змін до персоніфікованих даних (дати отримання уточнених даних від споживача, отриманих постачальником на письмовий запит щодо уточнення даних);
                </li>
                <li style={{ marginBottom: "10px" }}>
                  надання на вимогу побутового споживача даних, які підтверджують згоду споживача змінити умови постачання за договором постачання природного газу шляхом обрання іншої комерційної пропозиції постачальника (при зміні однієї комерційної пропозиції умов постачання природного газу (у тому числі базової річної пропозиції) на іншу комерційну пропозицію постачальника), - у строк не більше 15 днів з дня отримання відповідного запиту;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  надання повідомлення побутовому споживачу про відмову в наданні іншої комерційної пропозиції (при зміні однієї комерційної пропозиції умов постачання природного газу (у тому числі базової річної пропозиції) на іншу комерційну пропозицію постачальника) - у строк не більше 10 робочих днів з дня фіксації його згоди про перехід на іншу комерційну пропозицію;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  усунення порушення або надання обґрунтованої відмови побутовому споживачу у випадку отримання постачальником претензії від побутового споживача про порушення постачальником умов Правил постачання природного газу та договору постачання природного газу - у строк не більше 5 робочих днів з дня отримання претензії побутового споживача, складеної у довільній формі;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  розгляд акта-претензії споживача про відшкодування збитків - у строк не більше 20 робочих днів з моменту отримання постачальником поштового відправлення з позначкою про вручення;
                </li>
                <li style={{ marginBottom: "10px" }}>
                  розгляд письмового звернення споживача (у тому числі електронного звернення побутового споживача) з дня надходження звернення у строк:
                  <ul style={{ marginBottom: "10px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      до 30 днів;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      до 5 робочих днів для звернень щодо правильності рахунка/нарахувань за спожитий природний газ.
                    </li>
                  </ul>
                </li>
              </ol>
              <br />
              <br />
              <br />
              <p>
                <strong style={{ fontSize: '32px', fontWeight: '700' }}>
                  Розмір компенсацій та порядок їх надання за недотримання мінімальних стандартів якості при наданні постачальником послуг постачання природного газу
                </strong>
              </p>
              <p>
                Витяг з розділу 3 «Забезпечення мінімальних стандартів якості послуг постачання природного газу» МІНІМАЛЬНТХ СТАНДАРТІВ ТА ВИМОГ до якості обслуговування споживачів природного газу та порядок надання компенсації споживачам за їх недотримання
              </p>
              <p>
                У разі недотримання мінімальних стандартів якості послуг постачання природного газу, , постачальник сплачує споживачу компенсацію у розмірах, наведених нище.
              </p>
              <ol style={{ listStyle: "none" }}>
                <li style={{ marginBottom: "10px" }}>
                  3.5. Постачальник надає споживачу компенсацію за недотримання мінімальних стандартів якості послуг постачання природного газу шляхом врахування суми відповідної компенсації у рахунку за спожитий природний газ у строк не більше 45 днів з дня недотримання мінімального стандарту якості (у разі недотримання мінімального стандарту - з дати зміни умов договору постачання природного газу для непобутових споживачів).
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.6. Постачальник самостійно визначає факт недотримання мінімальних стандартів якості послуг постачання природного газу, визначає споживача, якому він має надати компенсацію за недотримання мінімальних стандартів якості, та надає таку компенсацію без додаткового звернення зі сторони споживача.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.7. Якщо сума компенсації перевищує суму рахунка за спожитий природний газ, постачальник ураховує суму невиплаченої компенсації в розрахунках майбутніх періодів.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  За наявності заборгованості споживача за спожитий природний газ компенсація надається в рахунок зменшення заборгованості споживача.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.8. Постачальник за домовленістю зі споживачем може надавати компенсацію іншим способом, у тому числі шляхом перерахування коштів споживачу за банківськими реквізитами.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.9. Постачальник зобов'язаний поінформувати споживача про надання йому компенсації шляхом зазначення у рахунку на оплату природного газу, у тому числі в «особистому кабінеті» споживача на вебсайті постачальника, або листом протягом 10 робочих днів з дня надання компенсації.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.10. У разі ненадання постачальником компенсації за недотримання мінімальних стандартів якості послуг постачання природного газу в порядку, визначеному
                  &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17/print#n217" target="_blank">
                    пунктом 3.4
                  </Link>
                  &nbsp;
                  цієї глави, споживач має право самостійно звернутися до постачальника із заявою за формою, наведеною в
                  &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17/print#n237" target="_blank">
                    додатку 4
                  </Link>
                  &nbsp;
                  до цих Стандартів та вимог.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  У разі необґрунтованої відмови у наданні компенсації або залишення заяви без розгляду споживач має право звернутись до НКРЕКП та її територіальних органів або до суду.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.11. У разі невиконання постачальником вимог
                  &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17/print#n218" target="_blank">
                    пункту 3.5
                  </Link>
                  &nbsp;
                  цієї глави щодо строків надання компенсацій сума відповідної компенсації подвоюється, а у разі прострочення граничного терміну надання компенсації на понад 90 днів - надається у п’ятикратному розмірі та має бути врахована при розрахунках у найближчому розрахунковому періоді.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.12. У разі невиплати постачальником компенсації за недотримання мінімальних стандартів якості послуг постачання природного газу НКРЕКП з урахуванням даних звітності, та за результатами планових або позапланових перевірок дотримання постачальником ліцензійних умов приймає рішення про накладення штрафу у розмірі, визначеному
                  &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/329-19" target="_blank">
                    Законом України
                  </Link>
                  &nbsp;
                  «Про ринок природного газу», а також щодо надання споживачам компенсації відповідно до
                  &nbsp;
                  <Link to="https://zakon.rada.gov.ua/laws/show/v1156874-17/print#n226" target="_blank">
                    пункту 3.11
                  </Link>
                  цієї глави.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.13. Надання компенсації за недотримання мінімальних стандартів якості послуг постачання природного газу не позбавляє споживача права у порядку, встановленому чинним законодавством, вимагати від постачальника відшкодування збитків (витрат), завданих унаслідок недотримання постачальником мінімальних стандартів якості.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  3.14. Компенсація за недотримання мінімальних стандартів якості послуг постачання природного газу не надається у випадках, якщо недотримання мінімальних стандартів якості послуг постачання природного газу було спричинено доведеними:
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <ul>
                    <li style={{ marginBottom: "10px" }}>
                      форс-мажорними обставинами, що підтверджується відповідно до чинного законодавства;
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      діями споживача, що призвели до затримки у наданні послуг. У разі доведених дій чи бездіяльності споживача, що призвели до затримки в наданні послуг, тривалість такої затримки не включається до тривалості надання послуги. Затримка в наданні послуг має бути документально підтверджена.
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      Компенсація не надається у разі письмової відмови споживача від отримання компенсації (із зазначенням недотриманого стандарту та розміру компенсації, від якої відмовляється споживач).
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                <Link to="https://dev-api-lez.inneti.net/uploads/business/size_compensation.pdf" target="_blank" download>
                  Розмір компенсації за недотримання постачальником мінімальних стандартів якості послуг постачання природного газу
                </Link>
              </p>
              <p>
                <Link to="https://dev-api-lez.inneti.net/uploads/business/statement.pdf" target="_blank" download>
                  Заява про надання компенсації за недотримання мінімальних стандартів та вимог до якості обслуговування споживачів при наданні послуг постачання природного газу
                </Link>
              </p>
              <br />
              <br />
              <br />
              <p>
                <strong style={{ fontSize: '32px', fontWeight: '700' }}>
                  Ефективне споживання природного газу
                </strong>
              </p>
              <br />
              <p>
                <strong>
                  Проста економія
                </strong>
              </p>
              <p>
                Енергоефективність - це, в першу чергу, ефективне використання енергії. Тому якщо, наприклад, не вмикати пальник газової плити на повну, обрати економний режим для газової колонки, не використовувати духовку для розігріву їжі, віддавати перевагу душу замість ванної, то можна без особливого дискомфорту скоротити споживання газу.
              </p>
              <p>
                Обирати найеконмічніший режим для котла важливо і для тих, хто використовує газ для опалення домівок. Можна також завезти звичку на кілька градусів зменшувати звичну температуру в приміщенні. На комфорті це майже не позначиться, а газу знадобиться менше.
              </p>
              <p>
                <strong>
                  Економія в деталях
                </strong>
              </p>
              <p>
                Є більш кардинальні методи збільшення енергоефективності, на які знадобляться фінансові витрати. Наприклад, часто слушним рішенням може стати заміна обладнання для опалення. Сучасні прилади, як правило, потребують меншої кількості енергії, ніж старі котли. До того ж, важливу роль в економії газу відіграє й стан самого приміщення, системи обігріву.
              </p>
              <p>
                Якщо увімкнути опалення, але відчинити вікно на провітрювання, для підтримки належної температури в приміщенні знадобиться більше палива. За тим же принципом зникає тепло, а з ним і додаткові кубометри газу, якщо в будинку є проблемні ділянки. Це можуть бути старі вікна чи неякісно встановлені нові, надто тонка підлога, погана теплоізоляція стін та горища, проблеми з функціонуванням системи обігріву, відсутність термоголовок на батареях.
              </p>
              <p>
                Для того, щоб оцінити стан будівлі, краще провести енергоаудит та отримати поради від фахівців: де і що потрібно виправити. Загалом заходи з енергоефективності допоможуть скоротити витрати енергії на понад 40%.
              </p>
              <p>
                <strong>
                  Економія газу в побуті:
                </strong>
              </p>
              <p>
                готуйте на такій потужності пальника, яка потрібна для вибраної Вами страви, після закипання вогонь треба зменшити у два рази. Враховуйте те, що найбільша температура знаходиться на кінчиках полум`я, тому не дозволяйте газу “облизувати” Ваші каструлі і сковорідки, адже це шкодить і посуду, і Вашому сімейному бюджету;
              </p>
              <p>
                намагайтеся рідше готувати малооб’ємні страви в газовій духовці. Іноді запекти два яблука або чотири картоплини краще в мікрохвильовій печі;
              </p>
              <p>
                для економії газу при нагріванні води газовою колонкою (котлом-колонкою), встановіть регулятор газу на приладі так, щоб не доводилось розбавляти гарячу воду холодною на водяному крані (змішувачі), та нагріваючись, вода повинна бути прийнятною для тіла (не пекти руки, не парувати) – це значно зменшить витрату газу для нагрівання води;
              </p>
              <p>
                для купання запасіться економічною душовою насадкою і намагайтеся приймати душ, а не повну ванну. Це економить і воду, і газ для її нагріву.
              </p>
            </Col>
          </Row>
          </div>
        </Container>
        {/* <SliderNews /> */}
      </section >
  )
}

export default Gas