import React, { forwardRef, useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import NavigationElementTab from './NavigationElementTab';
import { getMenuList } from '../../Axios/getPage';
import { orderServices } from '../../utils/news';
// import { useOutletContext } from 'react-router-dom';

const NavigationElement = forwardRef((props, ref) => {

  const [menuElectro, setMenuElectro] = useState([]);
  const [menuGaz, setMenuGaz] = useState([]);

  useEffect(() => {
    let active = false;
    
    getMenuList(`top-menu-${props.type}`).then((response) => {
      const [data] = response['hydra:member'];
      const orderMenus = orderServices(data.menuItems);

      if (!active) {
        if(props.type === 'company')
        {
          setMenuElectro(orderMenus);
        }
        else {
          setMenuGaz(orderMenus);
        }
      }
    });

    return () => {
      active = true;
    };
  }, [props.type, props.sliderContext])

  return (
    <div className={`top-navigation ${props.type}`} ref={ref}>
      <div className="top-navigation__close" onClick={() => props.handleClass(ref, props.type)}>
        <img src='/img/close-menu.svg' alt="" />
      </div>
      <Tab.Container className="tabs" defaultActiveKey="first">
        <Nav className="tabs__title">
          {/* <Nav.Item>
            <Nav.Link eventKey="first" className='tabs__title-electro'>Електроенергія</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second" className='tabs__title-gaz'>Природний Газ</Nav.Link>
          </Nav.Item> */}
        </Nav>
        <Tab.Content>
          {
            (props.type === 'company') &&
            <Tab.Pane eventKey="first" className="tabs__electro">
              <NavigationElementTab ref={ref} handleClass={props.handleClass} typeClass={props.type} links={menuElectro} />
            </Tab.Pane>
          }
          {
            (props.type === 'client') &&
            <Tab.Pane eventKey="first" className="tabs__gaz">
              <NavigationElementTab ref={ref} handleClass={props.handleClass} typeClass={props.type} links={menuGaz} />
            </Tab.Pane>
          }
        </Tab.Content>
      </Tab.Container>
    </div>
  )
});

export default NavigationElement