import React, { useState } from "react";
import TitleBlock from "../../components/TitleBlock";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

const About = () => {
  const [loading, SetLoading] = useState(false);

  return (
    loading
      ? <Loader />
      : <section className="page about">
        <div className="bg_img"></div>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <TitleBlock title={'Про нас'}></TitleBlock>
        </div>
        <Container>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <Col>
                <div className="about__title">
                  «ЛЕЗ Бізнес» – ваш провідник у сфері електропостачання та постачання природного газу
                </div>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <div className="about__links">
                <Link to={'#'} className="about__link">
                  Контакти
                </Link>
                <Link to={'#'} className="about__link">
                  Постачальник як
                  частина енергосистеми
                </Link>
              </div>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <Col>
                <div className='line-blue' style={{ marginBottom: "5px" }}></div>
                <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "10px", maxWidth: '777px' }}>
                  Енергія для нових досягнень
                </div>
                <p className="about__text-1" style={{ marginBottom: '80px' }}>
                  Ми віримо в потужну роль, яку грає енергія у вашому житті та бізнесі, і знаємо як зробити процес постачання електроенергії та газу надійним і стабільним. Компанія «ЛЕЗ Бізнес» була створена у 2023 році і спеціалізується на роботі з середнім та великим бізнесом. Основа нашої команди - провідні експерти ТОВ «Львівенергозбут», які мають багаторічний досвід роботи та відмінну репутацію на енергетичному ринку.
                </p>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <Col>
                <div className='line-blue' style={{ marginBottom: "5px" }}></div>
                <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "10px", maxWidth: '777px' }}>
                  Спільний розвиток
                </div>
                <p className="about__text-1" style={{ marginBottom: '80px' }}>
                  Ваш успіх - наша мета, і ми завжди готові бути вашим персональним провідником у досягненні цілей у сфері енергетики.
                </p>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <Col>
                <div className='line-blue' style={{ marginBottom: "5px" }}></div>
                <div className="content" style={{ fontSize: "48px", fontWeight: "800", lineHeight: "56px", marginBottom: "50px", maxWidth: '777px' }}>
                  Чому обирають нашу компанію?
                </div>
                <div className="about__cards">
                  <div className="about__card">
                    <div className="about__card--img about__card--img-1"></div>
                    <div className="about__card--title">
                      Надійність та
                      <br />
                      Стабільність
                    </div>
                    <div className="about__card--text">
                      Гарантоване стабільне
                      <br />
                      постачання будь-яких
                      <br />
                      обсягів електроенергії
                    </div>
                  </div>
                  <div className="about__card">
                    <div className="about__card--img about__card--img-2"></div>
                    <div className="about__card--title">
                      Вигідна
                      <br />
                      співпраця
                    </div>
                    <div className="about__card--text">
                      Зрозуміле ціноутворення
                      <br />
                      та різні варіанти графіків
                      <br />
                      оплат
                    </div>
                  </div>
                  <div className="about__card">
                    <div className="about__card--img about__card--img-3"></div>
                    <div className="about__card--title">
                      Cупровід
                    </div>
                    <div className="about__card--text">
                      Супровід клієнта при
                      <br />
                      проблемних питаннях у
                      <br />
                      стосунках з  учасниками
                      <br />
                      енергоринку
                    </div>
                  </div>
                  <div className="about__card">
                    <div className="about__card--img about__card--img-4"></div>
                    <div className="about__card--title">
                      Професійна
                      <br />
                      команда
                    </div>
                    <div className="about__card--text">
                      Ефективний сервіс від
                      <br />
                      досвідчених та
                      <br />
                      висококваліфікованих
                      <br />
                      фахівців
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <Row>
              <div className="contactAs-3 services__cont">
                <div className="contactAs-3__title">
                  Зв'яжіться з нами:
                </div>
                <div className="contactAs-3__content">
                  <div className="contactAs-3__texts">
                    <div>
                      Головний офіс:
                    </div>
                    <div>
                      <Link to={'https://goo.gl/maps/DreQiJcH9t31fyws6'} target="_blank">
                        79016, м. Львів, вул. Шевченка Т., 1
                      </Link>
                    </div>
                  </div>
                  <div className="contactAs-3__texts">
                    <div>
                      Телефон:
                    </div>
                    <div>
                      <Link to="tel:+380972595599">
                        +38 (097) 259-55-99
                      </Link>
                    </div>
                  </div>
                  <div className="contactAs-3__texts">
                    <div>
                      Електронна скринька:
                    </div>
                    <div>
                      <Link to="mailto:kanc@lez-business.com">
                        kanc@lez-business.com
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>
  )
}

export default About;