import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import SliderNews from '../News/SliderNews';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getFooterGas } from '../../Axios/getFooter';
import { AxiosInstance } from '../../Axios/axios';


export const NewPrefooterBlock = ({ slide, setTheme }) => {
  





  const [footerElectric, setFooterElectric] = useState([]);
  const [footerGas, setFooterGas] = useState([]);

  useEffect(() => {

    // AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
    AxiosInstance.get(`https://api-lez-business.inneti.net/api/menus?page=1&type=footer_electric`).then((response) => {
      if (response.status === 200)
        return (
          setFooterElectric(response.data['hydra:member'][0].menuItems)
        );
    });
  }, []);
  useEffect(() => {

    // AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
    AxiosInstance.get(`https://api-lez-business.inneti.net/api/menus?page=1&type=footer_gas`).then((response) => {
      if (response.status === 200)
        return (
          setFooterGas(response.data['hydra:member'][0].menuItems)
        );
    });
  }, []);

  const eyePad = useRef(null);
  
  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  
  function changeFonts(e,font){
    // change fonts global
    const root = document.querySelector('#root');
    root.style.setProperty('--fontSize', font);

    // change fonts footer
    const prefooter = document.querySelector('#footer');
    const allFootLinks = prefooter.querySelectorAll('a');
    for (let i = 0; i< allFootLinks.length; i++) {
      allFootLinks[i].style.fontSize = font;
      allFootLinks[i].style.lineHeight = font;
    }

    changeActiveEye(e);
  }

  function changeColor(e,color){ 
    changeActiveEye(e);
    const body = document.querySelector('body');
    if(color === 'black') {
      body.style.background = '#000000';
      setTheme('dark');
    }
    else{
      body.style.background = '#ffffff';
      setTheme('light');
    }
  }
  
  function changeActiveEye(e) {
    const closeParent = e.target.closest('.eye-font') || e.target.closest('.eye-color'); 
    const allActive = closeParent.querySelectorAll('.active');
    for (let i = 0; i< allActive.length; i++) {
      allActive[i].classList.remove('active');
    }
    e.target.classList.add('active'); 
  }

  function showEyePad(){
    eyePad.current.classList.toggle('active');
    document.querySelectorAll('.eye-font-a')[0].classList.add('active');
    document.querySelectorAll('.eye-font-a')[3].classList.add('active'); 
    
    const body = document.querySelector('body');
    const mainBg = document.querySelector('.main-bg');
    const vision = document.querySelector('.vision');
    
    body.style.filter = 'grayscale(1)';
    body.style.background = '#ffffff';
    mainBg.classList.add('active');
    vision.style.display = 'none';
    setTheme('light');
  }

  function closeEyePad(){
    eyePad.current.classList.remove('active');
    const body = document.querySelector('body');
    const root = document.querySelector('#root');
    const prefooter = document.querySelectorAll('.prefooter')[1];
    const vision = document.querySelector('.vision');
    const mainBg = document.querySelector('.main-bg');
    
    const allFootLinks = prefooter.querySelectorAll('a');
    for (let i = 0; i< allFootLinks.length; i++) {
      allFootLinks[i].style.fontSize = '12px';
    }

    const allActive = document.querySelectorAll('.eye-font-a');
    for (let i = 0; i< allActive.length; i++) {
      allActive[i].classList.remove('active');
    }
    
    body.style.removeProperty('filter');
    body.style.removeProperty('background');
    mainBg.classList.remove('active');
    root.style.setProperty('--fontSize', '16px');
    
    vision.style.display = 'block';
    setTheme('dark');
  }
  AOS.init();
  
  return (
    <Container id="footer">
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
      <div className="eye"   ref={eyePad}>
        <div className="eye-panel"> 
          <div className="eye-font">
            <p>Розмір шрифту:</p>
            <div className="eye-font-a active" style={{fontSize: "20px"}} onClick={(e)=>{changeFonts(e,'20px')}}>A</div>
            <div className="eye-font-a" style={{fontSize: "22px"}} onClick={(e)=>{changeFonts(e,'22px')}}>A</div>
            <div className="eye-font-a" style={{fontSize: "24px"}} onClick={(e)=>{changeFonts(e,'24px')}}>A</div>
          </div>
          <div className="eye-color">
            <p>Колір сайту:</p>
            <div className="eye-font-a active" onClick={(e)=>{changeColor(e,'white')}}>A</div>
            <div className="eye-font-a" onClick={(e)=>{changeColor(e,'black')}}>A</div>
          </div>
          <div className="eye-inner" onClick={closeEyePad}>
            <svg className='eye-svg' xmlns="http://www.w3.org/2000/svg" width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0"><path fill="#f9f9f9" d="M 372.125 191.214844 C 268.761719 51.300781 106.332031 51.46875 3.058594 191.214844 C 1.070312 194.117188 1.070312 197.933594 3.058594 200.835938 C 3.804688 201.914062 21.230469 227.546875 52.59375 253.507812 C 136.535156 323.558594 238.699219 323.554688 322.59375 253.507812 C 353.953125 227.546875 371.460938 201.914062 372.125 200.835938 C 374.117188 197.933594 374.117188 194.117188 372.125 191.214844 Z M 187.589844 289.171875 C 98.066406 289.171875 35.835938 215.683594 20.730469 196.023438 C 35.75 176.367188 97.898438 102.878906 187.589844 102.878906 C 277.117188 102.878906 339.347656 176.367188 354.453125 196.023438 C 339.347656 215.683594 277.117188 289.171875 187.589844 289.171875 Z M 187.589844 289.171875 " fillOpacity="1" fillRule="nonzero"/><path fill="#f9f9f9" d="M 187.589844 120.046875 C 145.6875 120.046875 111.589844 154.140625 111.589844 196.023438 C 115.695312 296.792969 259.503906 296.757812 263.59375 196.023438 C 263.59375 154.140625 229.492188 120.046875 187.589844 120.046875 Z M 187.589844 171.386719 C 173.984375 171.386719 162.949219 182.421875 162.949219 196.023438 C 162.949219 200.75 159.132812 204.566406 154.398438 204.566406 C 149.671875 204.566406 145.855469 200.75 145.855469 196.023438 C 145.855469 173.046875 164.523438 154.304688 187.589844 154.304688 C 198.765625 154.347656 198.78125 171.347656 187.589844 171.386719 Z M 187.589844 171.386719 " fillOpacity="1" fillRule="nonzero"/>
              </svg>
              <div className="eye-common">
                <p> Звичайна версія сайту</p>
              </div>
            </div>
          </div>
      </div>
      <Row className="prefooter" style={{ marginBottom: "80px" }}>
        <Col xs={12} lg={9}>
          <ul style={{ display: "flex", justifyContent: "start" }}>
            <li style={{ padding: "7px 25px", borderRight: "1px solid #484C50" }}>
              <Link to="/about" target="_blank" style={{  fontSize: "20px", textDecoration: "none" }}>
                Про нас
              </Link>
            </li>
            <li style={{ padding: "7px 25px", borderRight: "1px solid #484C50" }}>
              <Link to="/services" target="_blank" style={{ textDecoration: "none", fontSize: "20px" }}>
                Сервіси
              </Link>
            </li>
            <li style={{ padding: "7px 25px", borderLeft: "1px solid #484C50" }}>
              <Link to="/comunication" target="_blank" style={{ textDecoration: "none", fontSize: "20px" }}>
                Контакти
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} lg={3}>
          <div className="vision" onClick={showEyePad}>
            Людям з порушенням зору
          </div>
        </Col>
      </Row>
      {/* <SliderNews/> */}
      <Row className="prefooter" style={{ marginBottom: "50px" }}>
        <Row>
          <Col xs={12}>
            <h3 className="footer-head">{"ЛЕЗ Бізнес"}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h3 className="both" >
              <img src="/img/lamp-w.svg" alt="" width="20px" height="20px" style={{ marginRight: "5px" }} />
              Електроенергія</h3>
            <Col xs={4}>
              <Link to="/condition-electric" className="btn-white" target="_blank">
                Постачання
              </Link>
            </Col>
            <Col md={12}>
              <div className="myLine"></div>
            </Col>
            <Col className="prefooter">
              <ul style={{ listStyle: "none" }}>
              {footerElectric.map((item) => (
                <li key={item.id}>
                <Link to={item.slug} target="_blank">
                  {item.name}
                </Link>
              </li>
              ))}


                {/* <li>
                  <Link to="/condition-electric" target="_blank">
                    Загальні умови електропостачання
                  </Link>
                </li>
                <li>
                  <Link to="/client-info" target="_blank">
                    Інформація для клієнтів
                  </Link>
                </li>
                <li>
                  <Link to="/example" target="_blank">
                    Приклад роз’яснення рахунку за електричну енергію
                  </Link>
                </li>
                <li>
                  <Link to="/lawmaking" target="_blank">
                    Нормативні документи
                  </Link>
                </li>
                <li>
                  <Link to="/electro-service" target="_blank">
                    Електробезпека
                  </Link>
                </li>
                <li>
                  <Link to="/submission-procedure" target="_blank">
                    Порядок подання та розгляду електропостачальником звернень, скарг, претензій
                  </Link>
                </li> */}
              </ul>
            </Col>
          </Col>


          <Col md={6}>
            <h3 className="both" ><img src="/img/gas-w.svg" alt="" width="20px" height="20px" /> Природний газ</h3>
            <Col xs={4} className="text-end">
              <Col xs={12} className="my-2 foot-info">
                <Link to="/gas" className="btn-white" target="_blank">
                  Постачання
                </Link>
              </Col>
            </Col>
            <Col md={12}>
              <div className="myLine"></div>
            </Col>
            <Col>
              <ul style={{ listStyle: "none" }}>
                {footerGas.map((item,index) => (
                  <li key={index}>
                  <Link to={item.slug} target="_blank">
                    {item.name}
                  </Link>
                </li>
                ))}
                {/* <li>
                  <Link to="/gas" target="_blank">
                    Постачання газу
                  </Link>
                </li> */}
              </ul>
            </Col>
          </Col>
        </Row>
      </Row>
      </div>
    </Container >

  )
}
