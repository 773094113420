import TitleBlock from "../../components/TitleBlock";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComForYou from "../../components/ComOffers/ComForYou";
import ApiForm from "../../components/ApiForm";

const СomOffers = () => {

  return (
       <section className="comers">
        <div className="bg_img"></div>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <TitleBlock title={'Комерційні пропозиції'}></TitleBlock>
        </div>
        <Container className="comers__container">
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <Row className="comers__inner">
            <Col>
              <div className='line-blue' style={{ marginBottom: "20px" }}></div>
              <div className="content">
                <p> Обрати найкращу комерційну пропозицію - це перший крок до успішого енергопостачання для вашого бізнесу. <br/> Ви маєте великі обсяги споживання 
                    та потребуєте стабільного та надійного енергопостачання? Чи можливо ви прагнете оптимізувати свої витрати? Наші спеціалісти допоможуть вам у виборі 
                    та запропонують найкраще рішення. </p>
              </div>
            </Col>
          </Row>
          </div>
        </Container>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <ComForYou/>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <ApiForm id={1}/>
          </div>
      </section>
  )
}

export default СomOffers