import { Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavigationElement from "./Navigation/NavigationElement";
import { createRef, useRef } from 'react';


const Header = ({ sliderContext, handleScroll, targetRef }) => {

  const navigationCompany = createRef();
  const navigationClient = createRef();
  const refHeader = useRef(null);
  const pad = useRef(null);

  function changeSlide(id) {
    if (!sliderContext?.destroyed) {
      return sliderContext?.slideTo(id);
    }
  }


  function addClasses(ref, className) {
    if (className === 'company') {
      ref.current.classList.toggle('active');
      navigationClient.current.classList.remove('active');
      refHeader.current.classList.remove('client');
      refHeader.current.classList.toggle(className);
    }
    if (className === 'client') {
      ref.current.classList.toggle('active');
      navigationCompany.current.classList.remove('active');
      refHeader.current.classList.remove('company');
      refHeader.current.classList.toggle(className);
    }
  }

  function removeClasses() {
    navigationCompany.current.classList.remove('active');
    navigationClient.current.classList.remove('active');
    refHeader.current.classList.remove('client');
    refHeader.current.classList.remove('company');
    pad.current.style.display = "none";
  }

  const scrollToFooter = () => {
    // Find the footer element by ID (assuming it has an ID attribute)
    const footerElement = document.getElementById('footer');

    if (footerElement) {
      // Get the height of the footer element
      const footerHeight = footerElement.getBoundingClientRect().height;

      // Calculate the Y position to scroll to (footer's top position)
      // const scrollToY = footerElement.offsetTop - footerHeight;
      const scrollToY = footerElement.offsetTop;

      // Scroll to the footer
      window.scrollTo({
        top: scrollToY,
        behavior: 'smooth', // Use 'auto' if you want an instant scroll
      });
    }
  };


  return (
    <>
      <header className="header" ref={refHeader}>
        {/* <Container> */}
          <NavigationElement ref={navigationCompany} handleClass={addClasses} type={"company"} sliderContext={sliderContext} />
          <NavigationElement ref={navigationClient} handleClass={addClasses} type={"client"} sliderContext={sliderContext} />

          <Row className='align-items-center header__inner'>
            <Col md={2} className="text-start header__icons" >
              {/* <Link className='header__icons-fb' to='https://www.facebook.com/LvivEnergoZbut' target={'_blank'}> <img src='/img/fb.svg' alt="" /></Link> */}
              {/* <Link className='header__icons-youtube' to='https://www.youtube.com/@lvivenergozbut' target={'_blank'}> <img src='/img/youtube.svg' alt="" /></Link> */}
            </Col>
            <Col md={3} className="text-start header__company">
              <svg className='header__company-svg header__company-svg--left' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.256282 11.7489C-0.0854272 11.4142 -0.0854273 10.8715 0.256282 10.5368L4.88756 6L0.256281 1.46323C-0.0854277 1.1285 -0.0854277 0.585786 0.256281 0.251051C0.59799 -0.083684 1.15201 -0.083684 1.49372 0.251051L6.74372 5.39391C7.08543 5.72864 7.08543 6.27136 6.74372 6.60609L1.49372 11.7489C1.15201 12.0837 0.59799 12.0837 0.256282 11.7489Z" fill="#fafafa" />
              </svg>
              <button className='nav-btn company text-uppercase' onClick={() => { addClasses(navigationCompany, 'company'); pad.current.style.display = "block" }}>Електроенергія</button>
            </Col>
            <Col md={2} className="text-center logo__inner">
              <Link to='/'><div className="logo" onClick={() => changeSlide(1)}></div></Link>
            </Col>
            <Col md={3} className="text-end header__company">
              <button className='nav-btn client text-uppercase' onClick={() => { addClasses(navigationClient, 'client'); pad.current.style.display = "block" }}>Природний газ </button>
              <svg className='header__company-svg header__company-svg--right right' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.256282 11.7489C-0.0854272 11.4142 -0.0854273 10.8715 0.256282 10.5368L4.88756 6L0.256281 1.46323C-0.0854277 1.1285 -0.0854277 0.585786 0.256281 0.251051C0.59799 -0.083684 1.15201 -0.083684 1.49372 0.251051L6.74372 5.39391C7.08543 5.72864 7.08543 6.27136 6.74372 6.60609L1.49372 11.7489C1.15201 12.0837 0.59799 12.0837 0.256282 11.7489Z" fill="#fafafa" />
              </svg>
            </Col>
            
            <Col md={2} className="text-end menu-map">
              <NavLink to={'/search-page'} className={'search-svg'}>
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="73a2d828a9">
                  <path d="M 2.171875 2.171875 L 371 2.171875 L 371 371 L 2.171875 371 Z M 2.171875 2.171875 " clipRule="nonzero"/></clipPath></defs>
                  <path fill="#858A8F" d="M 131.203125 2.171875 C 60.160156 2.171875 2.171875 60.160156 2.171875 131.203125 C 2.171875 202.246094 60.160156 260.234375 131.203125 260.234375 C 163.425781 260.234375 192.863281 248.214844 215.519531 228.550781 L 223.367188 236.398438 L 223.367188 260.234375 L 326.332031 363.199219 C 336.507812 373.371094 353.023438 373.371094 363.199219 363.199219 C 373.371094 353.023438 373.371094 336.507812 363.199219 326.332031 L 260.234375 223.367188 L 236.398438 223.367188 L 228.550781 215.519531 C 248.214844 192.859375 260.234375 163.425781 260.234375 131.203125 C 260.234375 60.160156 202.246094 2.171875 131.203125 2.171875 Z M 131.203125 39.039062 C 182.324219 39.039062 223.367188 80.085938 223.367188 131.203125 C 223.367188 182.324219 182.324219 223.367188 131.203125 223.367188 C 80.085938 223.367188 39.039062 182.324219 39.039062 131.203125 C 39.039062 80.085938 80.085938 39.039062 131.203125 39.039062 Z M 131.203125 39.039062 " 
                  fillOpacity="1" fillRule="nonzero"/></svg>
               </NavLink>    
              <img src='/img/menu.svg' alt="" onClick={scrollToFooter} />
            </Col>
          </Row>
        {/* </Container> */}
      </header>
      <div className="pad" onClick={removeClasses} ref={pad} style={{ display: "none" }}></div>
    </>
  );
};

export default Header;