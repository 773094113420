import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'
import TitleBlock from '../TitleBlock'
import ComTarifPlansItem from './ComTarifPlansItem'
import { getMenuList } from '../../Axios/getPage'

const ComForYou = () => {

  const [comOffers, setComOffers] = useState(false);
  const [tarif, setTarif] = useState(0); 
  const [currentPay, setCurrentPay] = useState(0);

  useEffect(()=>{
    getMenuList('com-offers').then(response => {
        const [data] = response['hydra:member'];
        setComOffers(data.menuItems);
        setTarif(data.menuItems[0]?.id);
        setCurrentPay(data.menuItems[0]?.children[0]?.id);

    }).catch((err) => console.log(err))
  },[])

  function changeTarifs(e){
    const newTarifId = parseInt(e.target.value);
    // після зміни батьківського елементу Тарифи (tarif), змінюється список оплат, який має свої id, 
    const oldIndex = payList.children !== undefined && payList.children.findIndex(pay => pay.id === currentPay); // визначення старого індексу способу оплати
    const newPayIndex = comOffers && comOffers.findIndex(offer => offer.id === newTarifId); // визначення новго індексу комерційних пропозицій

    setTarif(newTarifId); // переключення на новий тариф
    setCurrentPay(comOffers[newPayIndex]?.children[oldIndex]?.id); // збереження позиції вибраного способу оплати після переключення на новий тариф
  }

  const payList = comOffers && comOffers.find(offer => offer.id === tarif);
  const selectTarif = payList.children !== undefined && payList.children.find(pay => pay.id === currentPay);

  return (
    <section className='comers__for'>
        <TitleBlock subtitle={'Комерційна пропозиція для вашої компанії'}/>
        <Container>
            <Row className='justify-content-between mb-5'>
                <Col md={7} className='comers__for-inner'>
                    <div className="comers__for-title">
                        <h4><span>01</span>Чи включає Комерційна пропозиція тариф Оператора системи розподілу?</h4>
                        <Link to={'/tarifs'}>Про встановлені тарифи
                        <svg style={{marginLeft:"5px"}} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <g clipPath="url(#clip0_258_698)">
                            <path d="M14.3177 0H8.86317C8.48661 0 8.18134 0.290737 8.18134 0.649358C8.18134 1.00798 8.48661 1.29872 8.86317 1.29872H12.6717L5.65381 7.98242C5.38753 8.23602 5.38753 8.64713 5.65381 8.90072C5.78691 9.02751 5.9614 9.0909 6.13589 9.0909C6.31038 9.0909 6.4849 9.02754 6.61803 8.9007L13.6359 2.21702V5.84417C13.6359 6.20279 13.9412 6.49353 14.3177 6.49353C14.6943 6.49353 14.9996 6.20279 14.9996 5.84417V0.649358C14.9995 0.290737 14.6943 0 14.3177 0Z" fill="white"/>
                            <path d="M11.5909 6.49351C11.2143 6.49351 10.9091 6.78424 10.9091 7.14286V12.987H1.36362V3.8961H7.5C7.87655 3.8961 8.18183 3.60536 8.18183 3.24674C8.18183 2.88812 7.87655 2.59741 7.5 2.59741H0.681826C0.305273 2.59741 0 2.88815 0 3.24677V13.6364C0 13.995 0.305273 14.2857 0.681826 14.2857H11.5909C11.9675 14.2857 12.2728 13.995 12.2728 13.6364V7.14286C12.2727 6.78424 11.9675 6.49351 11.5909 6.49351Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_258_698">
                            <rect width="15" height="14.2857" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        </Link>
                    </div>
                    <div className="comers__for-check" onChange={(e)=> changeTarifs(e)}>
                        {comOffers && comOffers.map(offer => {
                            return <React.Fragment key={offer.id}>  
                                        <input type="radio" name="com-check" className="com-check-input" id={`com-check1${offer.id}`} value={offer.id} defaultChecked = {offer.id === tarif}/>
                                        <label className="com-check-button" htmlFor={`com-check1${offer.id}`}>{offer.name}</label> 
                                    </React.Fragment>
                        })}
                    </div>

                    <div className="comers__for-title">
                        <h4><span>02</span>У який спосіб здійснюється оплата?</h4>
                    </div>
                    <div className="comers__for-check" onChange={(e)=>{setCurrentPay(parseInt(e.target.value))}}>
                        {payList && payList.children.map(pay => {
                            return <React.Fragment key={pay.id}>  
                                        <input type="radio" name="com-check-pay" className="com-check-input" id={`com-check2${pay.id}`} value={pay.id} defaultChecked = {pay.id === currentPay}/>
                                        <label className="com-check-button" htmlFor={`com-check2${pay.id}`}>{pay.name}</label>
                                    </React.Fragment>
                        })}

                    </div>
                <Col md={12} className='mt-5 link-arhiv-desctop'>
                    <Link to={'/page/arhiv_komerc'}>Архів комерційних пропозицій</Link>
                </Col>
                </Col>
                <Col md={3} className='plans-inner'>
                    {(selectTarif !== undefined && selectTarif.children?.length > 0) 
                        && selectTarif.children.map(tarif => {
                        return <ComTarifPlansItem
                                    nameParent = {selectTarif.name} 
                                    nameTariff = {tarif.name}
                                    namePay = {payList.name}
                                    url = {tarif.slug}
                                /> 
                    })}
                </Col>
            </Row>
            <Row>
                <Col md={12} className='mt-3 link-arhiv-mobile'>
                    <Link to={'/page/arhiv_komerc'}>Архів комерційних пропозицій</Link>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default ComForYou